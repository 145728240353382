import React from 'react'
import '../App.css'
import { BiDownArrowCircle ,BiStore, BiSupport,BiTimer  } from "react-icons/bi";
import { FaShippingFast } from "react-icons/fa";

function Home() {
  return (
    <div>
    <div id='homebuss'>
      <h1>Depop</h1>
      <h2>For all accessories</h2>
      <div id='span'>
        <span><BiStore/></span>
        <span><BiTimer/></span>
        <span><BiSupport/></span>
        <span><FaShippingFast/></span>
      </div>
      <a href='#!'>shop new</a>
    </div>
    <div id="home2">
      <div id='img1'>
        <h1>men's clothing</h1>
        <p>Men's clothing includes a variety of garment options such as shirts, t-shirts, polo shirts, sweaters, jackets, coats, pants, shorts, suits, and accessories like ties, hats, and belts. Clothing choices can be tailored to different occasions, from casual wear to formal events. Materials and styles can include denim, cotton, wool, leather, and various color and pattern options.</p>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxtY4V3GJOGtlXwdnvo1_ScoxhHoCxNYZSfg&usqp=CAU" alt="" />
      </div>
      <div id='img2'>
        <img src="https://www.thejoyjewels.com/cdn/shop/files/DSC3618.jpg?v=1691674922&width=3840" alt="" />
        <h1>jewelery</h1>
        <p>Jewelry, also known as jewellery, is decorative items worn for personal adornment, such as necklaces, earrings, bracelets, and rings. They are often made of precious metals, gemstones, and other materials. Jewelry can be used to indicate status, wealth, or fashion styles, and are often given as gifts for special occasions or to express love and affection.</p>
      </div>
      <div id='img3'>
        <h1>electronics</h1>
        <p>Electronics refers to the branch of science and technology that deals with the study and application of electrical circuits, components, and systems. It includes a wide range of devices and systems such as computers, smartphones, televisions, radios, cameras, and numerous other gadgets. Electronics have transformed the way we communicate, work, and live our lives, and they continue to evolve rapidly with each passing day.</p>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTygoqhvoQGlPY1dN9wT_gLegiW0-_Cn_XdGQ&usqp=CAU" alt="" />
      </div>
      <div id='img4'>
        <img src="https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/22120556/2023/2/25/986d6f97-fe9b-487a-9c64-f577e0a32c491677314970316KhushalKwomenEmbroideryKurtaandpalazzowithdupattaset7.jpg" alt="" />
        <h1>women's clothing</h1>
        <p>Women's clothing includes a wide range of apparel such as dresses, skirts, pants, blouses, tops, jackets, coats, and more. Women's clothing styles can vary from classic and timeless to trendy and stylish. Different fabrics, colors, patterns, and designs offer endless options to suit personal preferences and occasions. Many brands and retailers cater to women's clothing preferences, making it easy to find the perfect outfit for any occasion.</p>
      </div>
    </div>
    </div>
  )
}

export default Home