import React, {useState , useEffect} from 'react'
import axios from 'axios'
import '../App.css'

const Products=()=> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: "https://fakestoreapi.com/products",
    })
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
      
  },[]);

  return (

    <div id='lo'>
      {loading && 
        <h1>loading...</h1>
}
      {data.map((product) => {
        return (
          <div key={product.id} id='card'>
              <img src={product.image} alt='id' />
            <div id='card-descripion'>
              <h5>{product.title}</h5>
              <h6>Price: {`${product.price}`}</h6>
              <a href="#">Shop New</a>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Products;
