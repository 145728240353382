import React from 'react'
import { IoLogoTwitter , IoLogoTiktok, IoLogoYoutube ,IoLogoFacebook} from "react-icons/io5";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div id='footer'>
      <div id="footer-link">
      <div id="footer1" >
        <h3>Important links</h3>
        <a href="">privacy policy</a>
        <a href="">Using policy</a>
        <a href="">Privacy</a>
      </div>
      <div id="footer2">
        <h3>Important topics</h3>
        <a href="">Replacement  </a>
        <a href=""> retrieval </a>
        <a href="">Shipping</a>
        <a href="">Regions</a>
        <a href="">Pricing</a>
      </div>
      <div id="footer3">
        <h3>Social links</h3>
        <a href="" id='tw'><IoLogoTwitter/> #depop2023</a>
        <a href="" id='ti'><IoLogoTiktok/> #depop2023</a>
        <a href="" id='yo'><IoLogoYoutube/> #depop2023</a>
        <a href="" id='fa'><IoLogoFacebook/> #depop2023</a>
      </div>
 
      </div>
        <h1 id='copy'>sopping &copy; 2024</h1>

    </div>
  )
}

export default Footer