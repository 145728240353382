import React from 'react'
import { IoCartOutline ,IoHomeOutline ,IoAlert,IoChatboxOutline,IoBagAddOutline} from 'react-icons/io5';
import '../App.css'
import { Link } from 'react-router-dom';
const Navbar = () => {
window.onscroll= function(){scrolltop()}
 const scrolltop=()=>{
  if(document.body.scrollTop>100 || document.documentElement.scrollTop>100){
    document.getElementById("navbar").style.top ="0";
  }else{
    document.getElementById("navbar").style.top="-200px";
  }
 }
  return (
    <div id="navbar">
      <Link id="name" to="/">Depop</Link>
<nav>
      <Link to="/">Home</Link>
      <Link to="/Products"> products</Link>
      <Link to="/about"> about us</Link>
      <Link to="/contact">contact</Link>
</nav>
<div id='nav-mob'>
      <Link to="/"><IoHomeOutline/></Link>
      <Link to="/Products"> <IoBagAddOutline/></Link>
      <Link to="/about"> <IoAlert/></Link>
      <Link to="/contact"><IoChatboxOutline/></Link>
      <Link to="/contact"><IoCartOutline/></Link>
</div>
<span></span>

</div>
  )
}

export default Navbar

